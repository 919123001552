<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data PPJB">
        <b-row class="mt-2 mb-2" align="end">
          <b-col align="start" md="4">
            <v-select
              v-model="kavling_id"
              :options="kavlingOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(_id) => _id._id"
              placeholder="Pilih Kavling"
              @input="changeStatus()"
            />
          </b-col>
          <b-col align="start" md="4">
            <v-select
              v-model="marketing_id"
              :options="marketingOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(_id) => _id._id"
              placeholder="Pilih Marketing"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="1"> </b-col>
          <!-- spasi -->
          <b-col align="end" md="3">

          </b-col>
        </b-row>

        <b-row class="mt-2 mb-2" align="end">
          <b-col md="2">
            <b-form-input
              id="name"
              v-model="owner_name"
              placeholder="Cari Nama Pemilik"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="2">
            <b-form-input
              id="name"
              v-model="block_name"
              placeholder="Cari Nama Blok"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="2">
            <v-select
              v-model="payment_status"
              :options="paymentStatusBlockOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              placeholder="Pilih Status"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="2" align="start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="resetFilter()"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'payment_status'">
              <span v-if="props.row.payment_status === 'LUNAS'">
                <b-badge variant="success"> LUNAS </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'TEMPO'">
                <b-badge variant="warning"> TEMPO </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'BOOKING'">
                <b-badge variant="info"> BOOKING </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'TERSEDIA'">
                <b-badge variant="primary"> TERSEDIA </b-badge>
              </span>
            </span>

            <span v-else-if="props.column.field === 'create_time'">
              {{ props.row.create_time.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'purchase_date'">
              {{ props.row.purchase_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'length'">
              {{ props.row.length }} x {{ props.row.width }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <span v-else-if="props.column.field === 'price'">
              Rp.
              {{
                props.row.price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'total_price'">
              Rp.
              {{
                props.row.total_price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'total_paid'">
              Rp.
              {{
                props.row.total_paid
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'total_unpaid'">
              Rp.
              {{
                props.row.total_unpaid
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">

              <b-button
                v-if="props.row.isDownload === false "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                disabled
              >
                <span class="align-middle">Download</span>
              </b-button>

              <b-button
                v-if="props.row.isDownload === true "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="downloadFilter(props)"
              >
                <span class="align-middle">Download</span>
              </b-button>
              
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      required,
      email,

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Nama Kepemilikan",
          field: "name",
          thClass: "text-center",
        },
        {
          label: "Nama Kavling",
          field: "kavling_name",
          thClass: "text-center",
        },
        {
          label: "Nama Blok",
          field: "block_name",
          thClass: "text-center",
        },
        {
          label: "Status Pembayaran",
          field: "payment_status",
          thClass: "text-center",
        },
        {
          label: "Tanggal Pembelian",
          field: "purchase_date",
          thClass: "text-center",
        },
        {
          label: "Harga Jadi",
          field: "total_price",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],
      rows: [],

      tanggal_filter: "",

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      formDisabled: {
        purchase_date: "",
        buyer_name: "",
        facility: "",
        payment_status: "",
        kavling_name: "",
      },

      formSubmit: {
        name: "",
        kavling_id: "",
        price: "",
        length: "",
        width: "",
        is_booked: false,
        note: "",
        facility: "",
      },

      kavlingOptions: [],
      marketingOptions: [],
      paymentStatusBlockOptions: [],

      // old delete
      searchTerm: "",

      name: "",
      kavling_id: "",
      marketing_id: "",
      payment_status: "",
      buyer_name: "",
      facility: "",

      sort: "",

      create_time: "",

      show: false,
      aksi: "",
      id_kavling: "",

      nama: "",

      selected: null,
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
    this.getKavling();
    this.getMarketing();
    this.getPaymenStatusBlock();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.owner_name) {
        search0 = "&owner_name=" + this.owner_name;
      } else {
        search0;
      }

      let search1 = "";
      if (this.kavling_id) {
        search1 = "&kavling_id=" + this.kavling_id;
      } else {
        search1;
      }

      let search2 = "";
      if (this.block_name) {
        search2 = "&block_name=" + this.block_name;
      } else {
        search2;
      }

      let search3 = "";
      if (this.payment_status) {
        search3 = "&payment_status=" + this.payment_status;
      } else {
        search3;
      }

      let search4 = "";
      if (this.marketing_id) {
        search4 = "&marketing_id=" + this.marketing_id;
      } else {
        search4;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/ppjb/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=create_time&sort_dir=-1" +
              search0 +
              search1 +
              search2 +
              search3 +
              search4
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    downloadFilter(props) {
      window.open(
        "https://api.bsgproperti.mrizkyff.com/v1/ppjb/download/" + props.row._id
      );
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    getKavling() {
      return new Promise(() => {
        axios.get("v1/kavling/combo").then((res) => {
          this.kavlingOptions = res.data;
        });
      });
    },

    getMarketing() {
      return new Promise(() => {
        axios.get("v1/employee/combo/marketing").then((res) => {
          this.marketingOptions = res.data;
        });
      });
    },

    getPaymenStatusBlock() {
      return new Promise(() => {
        axios.get("v1/block/combo/payment_status").then((res) => {
          this.paymentStatusBlockOptions = res.data;
        });
      });
    },

    pilihKavling(event) {
      this.formSubmit.kavling_id = event;
    },

    resetFilter() {
      this.kavling_id = "";
      this.marketing_id = "";
      this.payment_status = "";
      this.block_name = "";
      this.owner_name = "";
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
